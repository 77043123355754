#page-releases {
    .section-releases {
        position: relative;
    }

    .track-listings {
        li {
            display: flex;
            margin-bottom: 5px;
            cursor: pointer;

            span {
                margin-right: 10px;
                display: inline-block;
                width: 20px;
            }

            &:hover {
                color:#d12da8;
                cursor: pointer !important;
            }

            a {
                cursor: pointer !important;
            }

            &:first-child {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;

                &:hover {
                    color: white;
                    cursor: default;
                }
            }
        }
    }

    .audio-player {
        padding: 10px 40px;
        background: #323232;

        >div {
            display: none;
        }

        iframe {
            display: none;

            &:first-child {
                display: block;
            }

            .playButton {
                background: red;
            }
        }

        @media (max-width: $tablet) {
            padding: 20px;
        }

        label {
            margin: 0 !important;
            font-size: .8em;
        }

        .player-controls {
            display: flex;
            justify-content: space-between; 
        }

        .progress-bar {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .progress {
                height: 4px;
                width: 100%;
                background: gray;

                .inner-bar {
                    height: 4px;
                    background: #d12da8;
                    width: 50%;
                }
            }
        }

        button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;

            &:hover {
                svg {
                    fill: #d12da8;
                }
            }

            svg {
                fill: white;
                width: 30px;
                height: 30px;
            }
        }
    }

    .release-box {
        border: 1px solid white;
        background: rgba(0,0,0,.8);
        display: flex;

        @media (max-width: $tablet) {
            flex-direction: column;
            margin-top: 80px;
        }

        .artwork {
            width: 50%;
            padding: 20px;
            border-right: 1px solid white;

            @media (max-width: $tablet) {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }

        .details {
            width: 50%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: $tablet) {
                width: 100%;
            }

            .release-title {
                padding: 20px 40px;
                padding-bottom: 40px;
                
                h1, label {
                    text-transform: uppercase;
                }

                @media (max-width: $tablet) {
                    padding: 20px;
                }
            }

            label {
                margin: 20px 0;
                display: block;
                
            }

            h1 {
                font-size: 3em;
                font-weight: normal;
                margin-bottom: 30px;

                @media (max-width: $tablet) {
                    font-size: 2em;
                }
            }
        }

        .nohover {
            background: none !important;
            cursor: default !important;
        }

        .links {
            display: flex;

            li {
                width: 25%;

                &:first-child, &:last-child {
                    a{ 
                        border-right: none;
                        border-left: none;
                    }
                }

                &:nth-child(2) {
                    a {
                        border-right: none;
                    }
                }

                a {
                    display: block;
                    text-align: center;
                    border: 1px solid white;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 1.1em;
                    height: 40px;
                    overflow: hidden;
                    color: white;
                    text-decoration: none;

                    @media (max-width: $tablet) {
                        font-size: 0.8em;
                    }

                    &:hover {
                        background: #d12da8;
                        cursor: pointer;
                    }

                    span {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: white;

                        @media (max-width: $tablet) {
                            display: none;
                        }
                    }

                    em {
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    .half {
        height: 50vh;
    }

    .third {
        height: 25vh;
    }
}