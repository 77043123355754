#page-store {
    position: relative;

    .content {
        @media (max-width: $tablet) {
            padding-top: 100px;
        }
    }

    .banner {
        background-image: url('static/images/shopbanner-elektron.jpg');
        padding-top: 30%;
        background-size: 100%;
        background-position: center;
        position: relative;
        margin-bottom: 30px;

        h1 {
            color: white;
            position: absolute;
            top: 0;
            font-size: 3em;
            font-weight: normal;
            top: 0.5em;
            left: 0.5em;

            @media (max-width: $tablet) {
                font-size: 2em;
            }
        }
    }

    .gallery-selector {
        display: flex;
        margin: 20px;

        div {
            overflow:hidden;
            margin-right: 10px;
            width: 25%;
            display: block;
            height: auto;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                >a {
                    transform: scale(1.1);
                    transition: transform 0.4s ease-in-out;
                }
            }

            a {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: none;
                display: block;
                height: auto;
                padding-top: 100%;
                width: 100%;
                transition: transform 0.4s ease-in-out;
            }
        }
        
    }

    .store-box {
        border: 1px solid white;
        background: rgba(0,0,0,.8);
        display: flex;
        min-height: 70vh;

        @media (max-width: $tablet) {
            flex-direction: column;
            margin-top: 0;
        }

        .btn-buynow {
            width: 100%;
            padding: 15px;
            border: none;
            background: #0031E0;
            color: white;

            &:hover {
                background: #001971;
                cursor: pointer;
            }
        }

        .artwork {
            width: 50%;
            padding: 0;
            background-size: cover;
            background-position: center;
            transition: transform 0.4s ease-in-out;
            border-right: 1px solid white;

            figure {
                background-size: contain;
                background-position: center;
                transition: transform 0.4s ease-in-out;
                padding-top: 100%;
                background-repeat: no-repeat;
                background-color: white;
            }
    
            @media (max-width: $tablet) {
                width: 100%;
                padding-top: 0%;
            }
            
        }

        .details {
            width: 50%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: $tablet) {
                width: 100%;
            }

            .release-title, .release-description {
                padding: 20px 40px;
                padding-bottom: 40px;

                @media (max-width: $tablet) {
                    padding: 20px;
                }
            }

            label {
                margin: 20px 0;
                display: block;
            }

            h1 {
                font-size: 3em;
                text-transform: uppercase;
                font-weight: normal;

                @media (max-width: $tablet) {
                    font-size: 2em;
                }
            }
        }

        .links {
            display: flex;

            li {
                width: 100%;

                &:first-child {
                    a{ 
                        border-right: none;
                        border-left: none;
                    }
                }

                &:last-child {
                    a {
                        border-right: none;
                    }
                
                }

                &:nth-child(2) {
                    a {
                        border-right: none;
                    }
                }

                a {
                    display: block;
                    text-align: center;
                    border: 1px solid white;
                    border-right: none;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 1.1em;
                    height: 40px;
                    overflow: hidden;

                    @media (max-width: $tablet) {
                        font-size: 0.8em;
                    }

                    &:hover {
                        background: #d12da8;
                        cursor: pointer;
                    }

                    &.active {
                        background: #d12da8;
                        span {
                            background: white;
                        }
                    }

                    span {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        border: 1px solid white;

                        @media (max-width: $tablet) {
                            display: none;
                        }
                    }

                    em {
                        padding-left: 5px;
                    }

                    input {
                        display: none;
                    }
                }
            }
        }
    }
}