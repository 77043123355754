@import './style/variables.scss';
@import './style/reset.scss';
@import './style/typography.scss';
@import './style/nav.scss';
@import './style/footer.scss';
@import './style/backgrounds.scss';
@import './style/lightgallery.scss';

@import './style/components/releases.scss';
@import './style/components/artists.scss';
@import './style/components/storeItems.scss';

@import './style/pages/home.scss';
@import './style/pages/releases.scss';
@import './style/pages/artists.scss';
@import './style/pages/store.scss';
@import './style/pages/contact.scss';



* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    box-sizing: border-box;
}


html {
    --color-background: black;
    background-color: var(--color-background);
    height: 100%;
    background: black;
}


body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    font-family: 'Aeonik';
    overflow-x: hidden;
    background: black;
}

.site-wrapper, .site-container {
    height: 100%;

    &.is-leaving {
        opacity: 0;
    }
}

.page {
    .half {
        height: 50vh !important;
    }
    
    .third {
        height: 25vh !important;
    }
}

.splash {
    position: fixed;
    background: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    transition: opacity 0.4s ease;

    &.is-active {
        top: 0;
        pointer-events: all;
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    &.locked {
        visibility: hidden;
        display: none;
    }

    .intro-logo {
        width: 500px;
        height: 500px;
    }
}

#mainVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 1;

    &.hidden {
        display: none;
    }
}

.preloader {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1090;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: black;
    transition: opacity 0.4s ease;

    img {
        width: 40px;
    }

    &.show {
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    @media screen and (orientation:landscape)
        and (min-device-width: 319px) 
        and (max-device-width: $mobile) {
            display: none;
    }
}

