#footer {
    z-index: 9999;
    background: rgba(0, 0, 0, .6);
    transition: 0.8s ease;
    position: relative;
    z-index: 999;

    footer {
        display: flex;
        color: white;
        padding: 30px;
        justify-content: space-between;
        height: 100%;

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        #sub-thanks {
            display: none;
        }

        .logo {
            width: 50px;
        }

        a {
            color: white;
            text-decoration: none;

            &:hover {
                color: #CE12A5;

                span {
                    background: #CE12A5 !important;
                }
            }
        }

        .left {
            width: 50%;
            display: flex;

            @media (max-width: $tablet) {
                width: 100%;
                justify-content: space-between;
                margin-bottom: 40px;
            }
        
            div {
                &:first-child {
                    width: 100px;
                }
            }

            ul {
                li {
                    text-transform: uppercase;
                    margin-bottom: 4px;
                    font-weight: 500;

                    &:nth-child(even) {
                        span {
                            border-radius: 50%
                        }
                    }

                    span {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: white;
                        margin-right: 5px;
                    }
                }
            }
        }

        .right {
            max-width: 28%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            @media (max-width: $tablet) {
                max-width: 100%;
            }

            .legal {
                font-size: .7em;
                text-align: right;
                margin-top: 30px;

                @media (max-width: $tablet) {
                    margin: 30px 0;
                }
            }

            p {
                font-size: 1em;
            }

            a.terms {
                font-size: .8em;
                display: block;
                margin-top: 15px;
                display: none;
            }

            .inputField {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                ::placeholder {
                    color: white;
                    opacity: 1; /* Firefox */
                  }
                  
                ::-ms-input-placeholder { /* Edge 12 -18 */
                color: white;
                }

                input[type="email"] {
                    background: #1315b3;
                    color: white;
                    padding: .8em;
                    border: none;
                    width: 80%;
                }

                button[type="submit"] {
                    background: #e7f5f8;
                    color: black;
                    padding: .8em;
                    border: none;
                    width: 20%;
                    cursor: pointer;
                    font-weight: 500;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    span {
                        background: black;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        left: -5px;
                        position: relative;
                        top: -1px;
                    }

                    em {
                        display: block;
                    }
                }
            }
        }
    }
}