.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
    background-position: bottom;

    &.releases {
        background-image: url('static/images/background-releases.jpg');
    }

    &.artists {
        background-image: url('static/images/background-releases.jpg');
    }

    &.store {
        background-image: url('static/images/background-store.jpg');
    }
}