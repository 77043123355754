nav {
    padding: 25px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;

    a.btn-cart {
        position: fixed;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        top: 42px;
        right: 23px;

        @media (max-width: $tablet) {
            top: 32px;
        } 

        &:hover {
            svg {
                stroke: #CE12A5;
            }
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: white;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
            transition: .4s ease;

            &.controls {
                position: relative;
                right: 40px;
                
                &:before {
                    content: ' ';
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    bottom: -3px;
                    right: -3px;
                    cursor: pointer;
                    z-index: 9;
                    pointer-events: all;
                }

                &:after {
                    content: ' ';
                    position: absolute;
                    top: -60px;
                    left: -60px;
                    bottom: -60px;
                    right: -60px;
                    cursor: pointer;
                    z-index: 9;
                    pointer-events: none;
                }

                &:hover {
                    &:before {
                        left: -200px;
                        right: -200px;
                        pointer-events: none;
                    }

                    &:after {
                        left: -500px;
                        right: -500px;
                        pointer-events: all;
                    }
                }
            }

            &.active {
                transform: translateX(-100px);
                transition: .4s ease;

                @media (max-width: $tablet) {
                    transform: translateX(-90px);
                }

                span.dota {
                    transform: translateX(-148px);
                    background: #CE12A5;

                    

                    @media (max-width: $tablet) {
                        transform: translateX(-71px);
                    }

                    &.active {
                        background: white;
                    }
                }

                span.dotb {
                    transform: translateX(-40px);
                    background: #CE12A5;

                    @media (max-width: $tablet) {
                        transform: translateX(-5px);
                    }

                    &.active {
                        background: white;
                    }
                }

                span.dotc {
                    transform: translateX(52px);
                    background: #CE12A5;

                    &.active {
                        background: white;
                    }
                }

          
                a.link-releases, a.link-artists, a.link-store {
                    visibility: visible;
                    pointer-events: all;
                    opacity: 1;
                    transition: 0.2s ease;
                }
            }

            &:first-child {
                min-width: 100px;
                display: flex;
                align-items: center;

                svg {
                    max-width: 60px;

                    @media (max-width: $tablet) {
                        max-width: 40px;
                    }
                }

                .logo-nav-title {
                    width: 140px;
                    margin: -10px 0 0 10px;
                    opacity: 0;
                    transition: .4s ease;

                    @media (max-width: $tablet) {
                        display: none !important;
                    }

                    &.show {
                        opacity: 1;
                        transition: .4s ease;

                        @media (max-width: $tablet) {
                            display: none !important;
                        }
                    }
                }
            }
            &:last-child {

                cursor: pointer;
                position: relative;


                span {
                    width: 12px;
                    height: 12px;
                    background: white;
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 2px 0 0;
                    transition: 0.4s ease;
                    transform: translateX(0);

                    &:last-child {
                        margin-right: 0;
                    }
                }

                

                a {
                    position: absolute;
                    text-transform: uppercase;
                    color: #CE12A5;
                    font-weight: 500;
                    font-family: 'Aeonik';
                    visibility: hidden;
                    pointer-events: none;
                    opacity: 0;
                    text-decoration: none;
                    transition: 0.2s ease;
                    z-index: 999;
                    font-size: 16px;

                    @media (max-width: $tablet) {
                        font-size: 12px;
                        top: 2px;
                    }

                    &:hover {
                        opacity: 1;
                        color: white;
                    }

                    &.link-releases {
                        left: -125px;

                        @media (max-width: $tablet) {
                            left: -54px;
                        }
                    }

                    &.link-artists {
                        left: 0px;

                        @media (max-width: $tablet) {
                            left: 30px;
                        }
                    }

                    &.link-store {
                        left: 105px;
                        top: 0;
                    }
                }
            }
        }
    }
}