#page-contact {
    position: relative;

    form {
        margin-top: 40px;

        ::placeholder {
            color: white;
            opacity: 1; /* Firefox */
            }
            
            ::-ms-input-placeholder { /* Edge 12 -18 */
            color: white;
            }

        input, textarea, select {
            width: 100%;
            padding: 10px 15px;
            border: none;
            background: #404040;
            margin-bottom: 10px;
            color: white;
            font-family: 'Aeonik';
            font-size:0.9em;
        }
    }


    #contact-thankyou {
        display: none;
    }

    .content {
        @media (max-width: $tablet) {
            padding-top: 100px;
        }
    }

    .contact-box {
        border: 1px solid white;
        background: rgba(0,0,0,.8);
        display: flex;
        min-height: 70vh;

        @media (max-width: $tablet) {
            flex-direction: column;
            margin-top: 0;
        }

        .artwork {
            width: 50%;
            padding: 0;
            background-size: cover;
            background-position: center;
            transition: transform 0.4s ease-in-out;
            border-right: 1px solid white;

            figure {
                background-size: 50%;
                background-position: center;
                transition: transform 0.4s ease-in-out;
                padding-top: 100%;
                margin: 20px;
                background-repeat: no-repeat;
            }
    
            @media (max-width: $tablet) {
                width: 100%;
                padding-top: 0%;
            }
            
        }

        .details {
            width: 50%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn-contact {
                width: 100%;
                padding: 15px;
                border: none;
                background: #0031E0;
                color: white;
    
                &:hover {
                    background: #001971;
                    cursor: pointer;
                }
            }

            @media (max-width: $tablet) {
                width: 100%;
            }

            .release-title, .release-description {
                padding: 20px 40px;
                padding-bottom: 40px;

                @media (max-width: $tablet) {
                    padding: 20px;
                }
            }

            label {
                margin: 20px 0;
                display: block;
            }

            h1 {
                font-size: 3em;
                text-transform: uppercase;
                font-weight: normal;

                @media (max-width: $tablet) {
                    font-size: 2em;
                }
            }
        }
    }
}