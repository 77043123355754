#page-artists {

    @media (max-width: $tablet) {
        padding-top: 80px;
    }
    .section-artists {
        position: relative;

        .artist-box {
            background: rgba(0,0,0,.8);
            display: flex;
            margin-bottom: 30px;
            border: 1px solid white;

            @media (max-width: $tablet) {
                flex-direction: column;
            }
    
            .artwork {
                width: 50%;
                padding: 0;
                background-size: cover;
                background-position: center;
                transition: transform 0.4s ease-in-out;
                padding-top: 50%;
                border-right: 1px solid white;
                
    
                @media (max-width: $tablet) {
                    width: 100%;
                    padding-top: 100%;
                }
            }
    
            .details {
                width: 50%;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                @media (max-width: $tablet) {
                    width: 100%;
                }
    
                .release-title, .release-description {
                    padding: 20px 40px;
                    padding-bottom: 40px;
    
                    @media (max-width: $tablet) {
                        padding: 20px;
                    }
                }
    
                label {
                    margin: 20px 0;
                    display: block;
                }
    
                h1 {
                    text-transform: uppercase;
                    font-weight: normal;
                    font-size: 3em;
    
                    @media (max-width: $tablet) {
                        font-size: 2em;
                    }
                }
            }
    
            .links {
                display: flex;
                border-top: 1px solid white;
                padding: 20px 0;
                display: flex;
                margin: 0 40px;
                justify-content: space-between;

                @media (max-width: $tablet) {
                    margin: 0 20px;
                   
                }

                a {
                    display: flex;
                    align-items: center;
                    color: white;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 0.8em;

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.3;
                    }

                    span {
                        width: 8px;
                        height: 8px;
                        background: white;
                        border: 50%;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    &:hover {
                        color: #CE12A5;

                        span {
                            background: #CE12A5;
                        }
                    }
                }

                div:first-child {
                    width: 30%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                div:last-child {
                    width: 285px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    flex-wrap: wrap;

                    @media (max-width: $tablet) {
                        flex-direction: column;
                        width: 200px;
                    }

                    a {
                        width: 50%;
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }
    }
    }
}